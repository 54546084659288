<template>
    <div class="container-fluid d-flex flex-column flex-grow-1 mt-3">
        <ListingComponent ref="AnswerMessagesList"
                          class="bg-white"
                          :addNewButton="addNewButton"
                          :requestProvider="requestProvider"
                          :columnConfig="columnConfig">
        </ListingComponent>
    </div>
</template>

<script>
    import ListingComponent from '@/components/listing/ListingComponent'

    export default {
        name: "AnswerMessagesListPage",
        data () {
            return {
                id: null,
                isBlockModal: false,
                isBlock:null,
                columnConfig: [
                    {
                        columnCode: 'action',
                        columnName: 'Action',
                        value: (item) => {
                            return (
                                <b-dropdown variant="link" class="listing-action">
                                <b-dropdown-item href={'#/answer-messages/edit/' + item.id}>Edit</b-dropdown-item>
                            </b-dropdown>
                        )
                        }
                    },
                    {
                        columnCode: 'MESSAGE_KEY',
                        columnName: 'Message Key',
                        link: '/answer-messages/edit',
                        align: 'left',
                        value: item => {
                            return item.messageKey
                        }
                    },
                    {
                        columnCode: 'ANSWER_TEXT',
                        columnName: 'Answer Text',
                        align: 'left',
                        value: item => {
                            return item.answerText
                        }
                    }
                ]
            }
        },
        methods: {
            requestProvider (payload) {
                return this.axios.get('answerMessage/list', payload)
            },
            addNewButton () {
                return null
            }
        },
        components: {
            ListingComponent
        }
    }
</script>

<style scoped>

</style>